import React, { Component, Fragment
    // , useEffect
   } from "react";
  import { Link, withRouter, NavLink } from "react-router-dom";
  import { connect } from "react-redux";
  import Fade from "react-bootstrap/Collapse";
  import NavDropdown from "react-bootstrap/NavDropdown";
  // import Nav from "react-bootstrap/Nav";
  import Button from "../../components/Button/Button";
  
  import FinappLogo from "../../assets/images/finappLogoNew.png";
  import bar from "../../assets/images/bar.png";
  import cross from "../../assets/images/cross.png";
  
  import { getValue, setValue, clearAll } from "../../utils/sessionManager";
  import { getNotification } from "../../actions/common";
  import { CLIENT_NAV, SUB_ADVISOR_NAV, ADMIN_NAV, SUBADVISOR_CLIENT_LOGIN_NAV } from "./navList";
  
  import { removeAlchemyServiceAuthToken } from "../../store/alchemyServiceAuth";
  import { removePulseDbAuthToken } from "../../store/pulseDbAuth";
  
  import "./Header.scss";
  
  class ClientNavbar extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLogged: false,
        user: {},
        isClient: false,
        logoUrl: "",
        isMenuOpen: false,
        navList: {},
        isMandatoryStepCompletedByClient: true,
      };
    }
  
  
    componentDidMount() {
      let { isMandatoryStepCompletedByClient } = this.state;
      const user = getValue("user");
      // let { isDefaultPasswordChanged, isUserPanCardUpdated, isUserAnswerQuestionnaire } = user;
      // if (!isDefaultPasswordChanged || !isUserPanCardUpdated || !isUserAnswerQuestionnaire) {
      //   isMandatoryStepCompletedByClient = false;
      // }
      
      let navList = {};
      if (user) {
        if (user.role === "SUBADVISOR") {
          navList = SUB_ADVISOR_NAV;
        } else if (user.role === "ADVISOR") {
          navList = ADMIN_NAV;
        } else if (user.role === "CLIENT") {
          navList = CLIENT_NAV;
        }
        this.setState(
          { isLogged: true, user, navList: navList, isMandatoryStepCompletedByClient },
          () => {
            this.updateState();
            this.getNotificationDetails();
          }
        );
      }
    }
  
    updateState = () => {
      const { user } = this.state;
      const { pathname } = this.props.location;
      const urls = pathname.split("/");
      const isAdvisorLogin = urls.find((item) => item === "advisor");
      if (isAdvisorLogin) {
        this.setState({ navList: SUB_ADVISOR_NAV, isClient: false });
      } else {
        if (user.role === "SUBADVISOR") {
          this.setState({ navList: SUBADVISOR_CLIENT_LOGIN_NAV ,isClient: true, user});
        }
      }
    };
  
    // for client notifion only
  
    getNotificationDetails = async () => {
      const { user } = this.state;
      if (user.role === "CLIENT") {
        try {
          const res = await getNotification();
          const newNotification =
            res.data.length > 0 ? res.data.filter((element) => element.is_viwed === false) : "";
  
          user.notification = newNotification;
          this.setState({ user });
        } catch (err) {
          console.error("getNotificationDetails, Server err: ", err.response);
          user.notification = 0;
          setValue("user", user);
          this.setState({ user });
        }
      }
    };
  
    // end for client notifion only
  
    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        let user = getValue("user");
        this.setState(
          {
            user,
          },
          () => this.updateState(),
          () => this.getNotificationDetails()
        );
      }
    }
  
  
    logOut = (e) => {
      this.props.removePulseDbAuthToken();
      this.props.removeAlchemyServiceAuthToken();
      clearAll();
      this.setState({ isLogged: false });
      this.props.history.push("/login/");
    };
  
    render() {
      const {
        isLogged,
        user,
        navList,
        isClient,
        isMenuOpen,
        // isMandatoryStepCompletedByClient,
      } = this.state;
      // const subdomain = getValue("subdomain");
      const logoUrl = getValue("logoUrl");
  
      // let notification_number = JSON.parse(localStorage.getItem("user"));
      return (
        <Fragment>
          <div
            className="row align-items-center justify-content-between navbar_wrapper  m-0"
             style={{ borderBottom:" 1px solid var(--Gray-05, #E8E8E8)" }}
          >
            <div className="col-md-3 col-lg-3 d-none d-md-block">

            </div>
  
            <div className="col-md-4 col-lg-4 d-none d-md-block px-3 py-2" 
              style={{ background: "#FFF",borderRadius:"30px" }}>
              <div className="d-flex flex-row justify-content-end align-items-center">
                <div
                  className="d-flex fle-row align-items-center justify-content-between"
                  style={{ width: "90%" }}
                >
                  {isLogged && (
                    <Fragment>
                      {user &&
                        navList.map((item) => {
                          return (
                            item.isIcon && (
                              <NavLink
                                to={item.path}
                                activeClassName="active"
                                className="icon_nav"
                                // data-tooltip
                                title={item.hoverText}
                              >
                                <i className={`${item.icon} clr-y p-r`} aria-hidden="true"></i>
                              </NavLink>
                            )
                          );
                        })}
  
                      <NavDropdown
                        // title={user && user.username}
                        id="nav-dropdown-support"
                        className="nav_dropdown_item text-nowrap"
                        title={user && 
                          user.role === "CLIENT" || isClient === true  ? user.client_name : user.advisor_name}
                      >
                        {user && user.role === "CLIENT" && (
                          <NavDropdown.Item
                            as="button"
                            onClick={(e) => this.props.history.push("/profile-details")}
                          >
                            Profile
                          </NavDropdown.Item>
                        )}
                        <NavDropdown.Item as="button" onClick={this.logOut} className="al_login_text">
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = {
    removeAlchemyServiceAuthToken,
    removePulseDbAuthToken,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientNavbar));
  
  // export default withRouter(Header);
  