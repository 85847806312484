import config from "../config";
import { apiCalls, authApiHeader } from "./apiCalls";
import store from "../store/index";

import { getValue } from "../utils/sessionManager";
import { hasSubdomain } from "../utils/utils";

export const getToken = () => {
  const url = config.base_url_2 + `/rest/api/v1/partner_login`;
  const reqObject = {
    partner: "Pulse-Demo",
    key: "Pulse$007",
  };
  return apiCalls("post", url, reqObject);
};

// export const postAdvisorData = (data) => {
//   const url = config.base_url + `/advisor/create`;
//   const reqObject = {
//     "advisor_id": "ARN-110384",
//     "sub_advisor_id": data.sub_advisor_id,
//     "pan_number": data.pan_no,
//     "advisor_name": data.name,
//     "mobile_number": data.mobile_number,
//     "email": data.email,
//     "euin_number": data.euin_no
//   }
//   return apiCalls('post', url, reqObject);
// }

// export const postAdvisorData = (data) => {
//   const url = config.base_url + `/user/create`;
//   const reqObject = {
//     "name":data.name,
//  "role":"subadvisor",
//  "email":data.email,
//  "mobile":data.mobile_number,
//  "advisor_code":"ARN-98444",
//  "sub_advisor_code":data.sub_advisor_id,
//  "advisor_type":"MFD",
//  "pan_number":data.pan_no,
//  "password":"Pulse@123",
//  "company":"Pulse Labs"
//   }
//   return apiCalls('post', url, reqObject);
// }
export const postAdvisorData = async (values) => {
  const url = config.base_url + `/user/create`;
  // const url = `http://localhost:9601/user/create`;
  const authToken = store.getState().alchemyServiceAuthToken.alchemyServiceAuthToken;
  const headerObj = { Authorization: `Bearer ${authToken}` };
  const user = getValue("user");
  const subdomain = hasSubdomain();
  const reqObject = {
    name: values.name,
    role: "subadvisor",
    email: values.email,
    advisor_id: user.advisor_id,
    advisor_type: "MFD",
    company: "",
    sub_domain: subdomain || "",
  };
  try {
    const result = await authApiHeader("post", url, headerObj, reqObject);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("postAdvisorData, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getAdvisorData = async (values) => {
  const url = config.base_url + `/user/advisor_info`;

  const authToken = store.getState().alchemyServiceAuthToken.alchemyServiceAuthToken;
  const headerObj = { Authorization: `Bearer ${authToken}` };
  const reqObject = {
    advisor_id: values.advisor_id,
  };
  try {
    const result = await authApiHeader("post", url, headerObj, reqObject);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorData, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getUserModuleRule = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/user_module_rule`;
  // const user = getValue("user");

  const reqObject = {
    advisor_id: values.advisorId,
    client_id: values.clientId,
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getUserModuleRule, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const saveUserModuleRule = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/user_module_rule`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    client_id: values.client_id,
    investment_module_rule: values.investment_module_rule,
    dreams_module_rule: values.dreams_module_rule,
    assets_maping_module_rule: values.assets_maping_module_rule,
    holding_module_rule: values.holding_module_rule,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorRule, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const updateUserModuleRule = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/user_module_rule/` + values.module_rule_id;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    client_id: values.client_id,
    investment_module_rule: values.investment_module_rule,
    dreams_module_rule: values.dreams_module_rule,
    assets_maping_module_rule: values.assets_maping_module_rule,
    holding_module_rule: values.holding_module_rule,
  };
  try {
    const result = await apiCalls("put", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateUserModuleRule, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// export const deleteRealAssets = (values) => {
//   const url = config.base_url_4 + "/api/v1/assets/real_assets/" + values.asset_id;
//   const user = getValue("user");
//   const reqObject = {
//     asset_type: "Real Asset",
//     user_name: user.role === "SUBADVISOR" ? user.username : user.client_name,
//     created_by: values.created_by,
//     client_email: values.client_email || "",
//     // "client_email":"sandeep9663493384@gmail.com",
//     sub_advisor_email: user.advisor_email || "sandeep9663493384@gmail.com",
//   };

//   return authApiCalls("delete", url, reqObject);
// };

export const verifySubdomain = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/verify_subdomain`;

  const reqObject = {
    subdomain: values.subdomain,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("verifySubdomain, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getAdvisorCompanySettings = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_settings`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorCompanySettings, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getPreloginAdvisorCompanySettings = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_settings`;
  const advisorId = getValue("advisorId");

  const reqObject = {
    advisor_id: advisorId || "ARN-98444",
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorCompanySettings, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const saveAdvisorCompanySettings = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_settings`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    company_name: values.company_name,
    support_phone_no: values.support_phone_no,
    support_email_id: values.support_email_id,
    website: values.website,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("saveAdvisorCompanySettings, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const updateAdvisorCompanySettings = async (values, id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_settings/` + id;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    company_name: values.company_name,
    support_phone_no: values.support_phone_no,
    support_email_id: values.support_email_id,
    website: values.website,
  };
  try {
    const result = await apiCalls("put", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateAdvisorCompanySettings, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const deleteAdvisorCompanySettings = async (id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_settings/` + id;

  try {
    const result = await apiCalls("delete", url, {});
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("deleteAdvisorCompanySettings, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getAdvisorCompanyBranding = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_branding`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorCompanyBranding, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const saveAdvisorCompanyBranding = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_branding`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    subdomain: values.subdomain,
    logo_file_type: values.logo_file_type,
    logo_s3_key: values.logo_s3_key,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("saveAdvisorCompanyBranding, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const updateAdvisorCompanyBranding = async (values, id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_branding/` + id;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    subdomain: values.subdomain,
    logo_file_type: values.logo_file_type,
    logo_s3_key: values.logo_s3_key,
  };
  try {
    const result = await apiCalls("put", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateAdvisorCompanyBranding, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const deleteAdvisorCompanyBranding = async (id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_company_branding/` + id;

  try {
    const result = await apiCalls("delete", url, {});
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("deleteAdvisorCompanyBranding, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getAdvisorClientTestinomial = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_client_testinomial`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id || "ARN-98444",
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorClientTestinomial, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getPreLoginAdvisorClientTestinomial = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_client_testinomial`;
  const advisorId = getValue("advisorId");

  const reqObject = {
    advisor_id: advisorId || "ARN-98444",
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorClientTestinomial, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const saveAdvisorClientTestinomial = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_client_testinomial`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    rating: values.rating,
    title: values.title,
    description: values.description,
    client_name: values.name,
    client_designation: values.designation,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("saveAdvisorClientTestinomial, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const updateAdvisorClientTestinomial = async (values, id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_client_testinomial/` + id;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    rating: values.rating,
    title: values.title,
    description: values.description,
    client_name: values.name,
    client_designation: values.designation,
  };
  try {
    const result = await apiCalls("put", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateAdvisorClientTestinomial, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const deleteAdvisorClientTestinomial = async (id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_client_testinomial/` + id;

  try {
    const result = await apiCalls("delete", url, {});
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("deleteAdvisorClientTestinomial, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getAdvisorMarketing = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_marketing`;
  const user = getValue("user") ?? getValue("advisorId");

  const reqObject = {
    advisor_id: user.advisor_id,
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorCompanySettings, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getAdvisorMarketingPreLogin = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_marketing`;
  const advisorId = getValue("advisorId");

  const reqObject = {
    advisor_id: advisorId || "ARN-98444",
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getAdvisorCompanySettings, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const saveAdvisorMarketing = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_marketing`;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    facebook_id: values.facebookId,
    instagram_id: values.instagramId,
    twitter_id: values.twitterId,
    linkedin_id: values.linkedinId,
    youtube_link_list: JSON.stringify(values.youtubeLinkList),
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("saveAdvisorMarketing, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const updateAdvisorMarketing = async (values, id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_marketing/` + id;
  const user = getValue("user");

  const reqObject = {
    advisor_id: user.advisor_id,
    facebook_id: values.facebookId,
    instagram_id: values.instagramId,
    twitter_id: values.twitterId,
    linkedin_id: values.linkedinId,
    youtube_link_list: JSON.stringify(values.youtubeLinkList),
  };
  try {
    const result = await apiCalls("put", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateAdvisorMarketing, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const deleteAdvisorMarketing = async (id) => {
  const url = config.base_url_4 + `/api/v1/advisor/advisor_marketing/` + id;

  try {
    const result = await apiCalls("delete", url, {});
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("deleteAdvisorMarketing, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const saveClientNewsletter = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/client_newsletter`;
  const advisorId = getValue("advisorId");

  const reqObject = {
    advisor_id: advisorId,
    client_email: values.client_email,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("saveClientNewsletter, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getClientNewsletter = async () => {
  const url = config.base_url_4 + `/api/v1/advisor/client_newsletter`;
  const advisorId = getValue("advisorId");

  const reqObject = {
    advisor_id: advisorId,
  };
  try {
    const result = await apiCalls("get", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getClientNewsletter, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const updateBseCredential = async (values) => {
  const url = config.base_url_4 + "/api/v1/advisor/bse_credential";
  try {
    const result = await apiCalls("post", url, values);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateBseCredential, server err:", error.message);
      throw new Error(error.message);
    }
  }
};
