import React, {
  Component,
  Fragment,
  // , useEffect
} from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "react-bootstrap/Collapse";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Nav from "react-bootstrap/Nav";
import Button from "../../components/Button/Button";

import FinappLogo from "../../assets/images/finappLogoNew.png";

import { getValue, setValue, clearAll } from "../../utils/sessionManager";
import { getNotification } from "../../actions/common";
import { CLIENT_NAV, SUB_ADVISOR_NAV, ADMIN_NAV, SUBADVISOR_CLIENT_LOGIN_NAV } from "./navList";

import { removeAlchemyServiceAuthToken } from "../../store/alchemyServiceAuth";
import { removePulseDbAuthToken } from "../../store/pulseDbAuth";

import "./Header.scss";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      user: {},
      isClient: false,
      logoUrl: "",
      isMenuOpen: false,
      navList: [],
      isMandatoryStepCompletedByClient: true,
    };
  }

  componentDidMount() {
    let { isMandatoryStepCompletedByClient } = this.state;
    const user = getValue("user");
    // let { isDefaultPasswordChanged, isUserPanCardUpdated, isUserAnswerQuestionnaire } = user;
    // if (!isDefaultPasswordChanged || !isUserPanCardUpdated || !isUserAnswerQuestionnaire) {
    //   isMandatoryStepCompletedByClient = false;
    // }

    let navList = {};
    if (user) {
      if (user.role === "SUBADVISOR") {
        navList = SUB_ADVISOR_NAV;
      } else if (user.role === "ADVISOR") {
        navList = ADMIN_NAV;
      } else if (user.role === "CLIENT") {
        navList = CLIENT_NAV;
      }
      this.setState(
        { isLogged: true, user, navList: navList, isMandatoryStepCompletedByClient },
        () => {
          this.updateState();
          this.getNotificationDetails();
        }
      );
    }
  }

  updateState = () => {
    const { user } = this.state;
    const { pathname } = this.props.location;
    const urls = pathname.split("/");
    const isAdvisorLogin = urls.find((item) => item === "advisor");
    if (isAdvisorLogin) {
      this.setState({ navList: SUB_ADVISOR_NAV, isClient: false });
    } else {
      if (user.role === "SUBADVISOR") {
        this.setState({ navList: SUBADVISOR_CLIENT_LOGIN_NAV, isClient: true, user });
      }
    }
  };

  // for client notifion only

  getNotificationDetails = async () => {
    const { user } = this.state;
    if (user.role === "CLIENT") {
      try {
        const res = await getNotification();
        const newNotification =
          res.data.length > 0 ? res.data.filter((element) => element.is_viwed === false) : "";

        user.notification = newNotification;
        this.setState({ user });
      } catch (err) {
        console.error("getNotificationDetails, Server err: ", err.response);
        user.notification = 0;
        setValue("user", user);
        this.setState({ user });
      }
    }
  };

  // end for client notifion only

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let user = getValue("user");
      this.setState(
        {
          user,
        },
        () => this.updateState(),
        () => this.getNotificationDetails()
      );
    }
  }

  logOut = (e) => {
    this.props.removePulseDbAuthToken();
    this.props.removeAlchemyServiceAuthToken();
    clearAll();
    this.setState({ isLogged: false });
    this.props.history.push("/login/");
  };

  render() {
    const {
      isLogged,
      user,
      navList,
      isClient,
      isMenuOpen,
      // isMandatoryStepCompletedByClient,
    } = this.state;
    const logoUrl = getValue("logoUrl");

    return (
      <Fragment>
        <div className="sidenav pt-0 px-4">
          <div className="text-center mb-4">
            <div style={{ padding: "5px", height: "80px" }}>
              <Link to="/">
                <img src={logoUrl ?? FinappLogo} alt="logo" className="brand_responsive_img" />
              </Link>
            </div>
          </div>

          {navList.map((item) => {
            return (
              !item.isIcon &&
              (item.isSubscriptionModal ? (
                item.isSubscriptionActive() && (
                  <NavLink
                    className="nav_link text-nowrap sidebar_content mb-2"
                    activeClassName="bg_clr_21 text_clr_24 br_12"
                    key={item.key}
                    to={item.path}
                  >
                    <span>
                      <i className={`mr-2 ${item.faIcon}`} aria-hidden="true"></i>
                    </span>{" "}
                    {item.value}
                  </NavLink>
                )
              ) : (
                <NavLink
                  className="nav_link text-nowrap sidebar_content mb-2"
                  activeClassName="bg_clr_21 text_clr_24 br_12"
                  key={item.key}
                  to={item.path}
                >
                  <span>
                    <i className={`mr-2 ${item.faIcon}`} aria-hidden="true"></i>
                  </span>{" "}
                  {item.value}
                </NavLink>
              ))
            );
          })}

          {/* <div className="sidebar_content">
  {item.value}
            </div> */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  removeAlchemyServiceAuthToken,
  removePulseDbAuthToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar));

// export default withRouter(Header);
