import React, { Fragment } from "react";
// import Header from "../components/Header/Header";
// import NewHeader from "../components/Header/NewHeader";
import NewHeader1 from "../components/Header/NewHeader1"
import ClientNavbar from "../components/Header/ClientNavbar";
import SideBar from "../components/Header/SideBar";
import UseLogNavigation from './useLogNavigation';
import { getValue } from "../utils/sessionManager";

const AppLayout = (props) => {
  UseLogNavigation()
  const user = getValue("user")
  return (
    <Fragment>
      {/* <NewHeader /> */}
      {/* <Header /> */}

      {user && user.role !== "CLIENT" && (
        <>
          <NewHeader1 />
          <div>{props.children}</div>
        </>
      )}
      {user && user.role === "CLIENT" && (
        <div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2 p-0">
                <SideBar />
              </div>
              <div className="containerStyle bg_clr_25 col-md-10 p-0">
                <ClientNavbar />
                {props.children}</div>
            </div>

          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AppLayout;
